<template>
  <div class="flex" style="height:100%">
    <!-- left -->
    <div class="background left">
      <div class="size-14 font-text2">서비스 운영을 위한 주문 방식을 추가할 수 있어요.</div>
      <div class="size-24">내 서비스에 <b>적합한 주문 방식</b>을 선택해주세요.</div>

      <div class="flex-align wrap margin-top-32" style="margin-left:-6px">
        <div v-for="(pack, p_idx) in packages"
             :key="`pack-${p_idx}`"
             :class="{'clicked': selectedItem && pack.id === selectedItem.id, 'selected': isSelected(pack)}"
             class="package-box-st unselect" style="width:150px;height:150px" @click="clickItem(pack)">
          <!--<i class="material-icons size-34" style="margin:16px">{{ pack.icon }}</i>-->
          <img :src="pack.icon" style="margin-top:20px" :style="iconStyle(pack)">
          <div class="margin-top-12">{{ pack.name }}</div>
        </div>
      </div>
    </div>
    <!-- right -->
    <div class="right" v-if="selectedItem.name !== ''">
      <div class="text-center">
        <img style="width:220px" :src="selectedItem.img"/>
      </div>

      <div class="flex-between" style="min-width:200px">
        <div>
          <div class="weight-600 size-18">{{ selectedItem.name }}</div>
          <div class="margin-top-12">
            <div class="discount-price size-13">{{ selectedItem.price.org_price|currency }}</div>
            <div class="weight-600 font-primary">{{ selectedItem.price.price | currency }}</div>
          </div>
        </div>
        <button class="button" @click="clickSelect"
                :class="{'is-primary-2': !isSelected(selectedItem), 'is-primary': isSelected(selectedItem)}"
        >{{ isSelected(selectedItem) ? '선택됨' : '선택하기' }}</button>
      </div>

      <div class="font-text2 margin-top-20" v-html="selectedItem.info"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SuperBuilderStep3",
    props: {
      service: {
        type: Object
      },
      alreadyPacks: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    created() {
      this.getData();
    },
    data() {
      return {
        packages: [],
        selectedItem: {
          name: '',
          id:0
        }
      }
    },
    methods: {
      iconStyle(pack) {
        let index = this.service.conversions.findIndex(item => { return item.id === pack.id });
        if(index === -1) {
          return {}
        } else {
          return {
            filter: 'invert(53%) sepia(86%) saturate(1648%) hue-rotate(344deg) brightness(100%) contrast(99%)'
          }
        }
      },
      isSelected(pack) {
        return this.service.conversions.findIndex(item => { return item.id === pack.id }) > -1;
      },
      clickSelect() {
        let index = this.service.conversions.findIndex(item => { return item.id === this.selectedItem.id });
        if(index === -1) {
          this.service.conversions.push(this.selectedItem);
        } else {
          this.service.conversions.remove(index);
        }
      },
      clickItem(item) {
        this.selectedItem = item;
      },
      getData() {
        this.$axios.get('/user/0/mapping/product?category=6&fields=id,name,params,img,price,category,info&page_length=100&ordering=name').then(res=>{
          res.data.data = res.data.data.filter(item=>{
            item.icon = item.params.filter(param => { return param.name === '아이콘' })[0].value;
            item.key = item.params.filter(param => { return param.name === 'key' })[0].value;
            item.pay_type = item.params.filter(param => { return param.name === '결제방식' })[0].value[0].name;
            item.price.org_price = item.price.price;
            item.price.price = item.price.price - item.price.discount_price;
            let packIndex = this.alreadyPacks.findIndex(pack => { return pack.key === item.key });

            if(packIndex === -1) {
              return true;
            } else if(this.alreadyPacks[packIndex].setting.payment_type !== item.pay_type && item.pay_type !== '결제없음') {
              return true;
            }
            return false;
          });
          this.packages = res.data.data;
          this.selectedItem = this.packages[0];
        });
      },
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .package-box-st
    text-align center
    padding 12px 18px
    border 1px solid $border2
    border-radius 8px
    margin 6px
  .clicked
  .package-box-st:hover
    background #f9f9f9
    border 0
    box-shadow 0 5px 5px 0 rgba(0,0,0,0.16)

  .selected
    border 1px solid $primary
    color $primary

  .left
    width 65%
    height 100%
    padding-left 15%
    overflow-y auto
    padding-top 30px
    padding-bottom 300px

  .right
    width 35%
    background-color white
    padding 24px 54px

  .discount-price
    text-decoration line-through
    color #797979
</style>
